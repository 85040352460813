:root {
    --padding: 1%;
 /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%;
 /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em;
 /* must NOT be a percentage value */
    --vertical-margin: 1em;
 /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    /* change settings for headings */
    --header-bg-color: #000;
    --header-padding: 0.9em 0 0;
    /* change colors for topmenu */
    --topmenu-bg: #fff;
    --topmenu-color: #000;
    --topmenu-li-a-padding: 10px 15px;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/PlayfairDisplay.css";
@import "/fontmodules/NewsCycle.css";
/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/

section {
    min-height: 50vh;
    padding: 5% 0;
}

.white {
    background-color: white;
}

.gray {
    background-color: #eee;
}

.darkgray {
    background-color: #2F2F2F;
}

.black {
    background-color: #000;
}

.purple {
    background-color: #3C1653;
}

.page {
    min-height: 80vh;
}
    

/* header
---------------------------------------------------------*/
header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: var(--header-bg-color);
    text-transform: none;
    padding: var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    z-index: 100;
}

header .container {
    padding: 0 0.5em;
}

header a {
    color: white;
}

.menu li {
    margin: 0;
}

.menu li a {
    padding: var(--topmenu-li-a-padding);
    color: white;
    font-family: "Myriad Pro", arial, helvetica, sans-serif;
    text-transform: uppercase;
    font-size: 0.9rem;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color: var(--topmenu-color);
}

.menu .dropdown ul {
    background: #fff;
    z-index: 100;
}

.logo {
    padding: 0;
}

.logo a {
    font-size: 0;
}

.topline {
    margin-top:0.6em;
    border-bottom:#222 solid 1px;
    padding:0.3em 0;
}


/* slider
---------------------------------------------------------*/

.slick-slider {
    margin: 0 auto;
    position: relative;
}

.startsida .slick-track {
    height: calc(70vh - 112px);
}

.slick-slide {
    background-size: cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.bildspel_titel h1, .bildspel_titel h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: white;
}

.bildspel_titel {
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    width: 100%;
    max-width: 890px;
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    display: block;
    margin: 0 auto;
}

.bildspel_titel h1, .bildspel_titel h2 {
    font-size: 3.8rem;
    margin: 0;
    letter-spacing: 1pt;
    font-weight: 700;
}

.slick-prev, .slick-next {
    width: auto;
    height: auto;
}

.slick-next {
    right: 50px;
    z-index: 100;
}

.slick-prev {
    left: 50px;
    z-index: 100;
}

.slick-next:hover, .slick-prev:hover {
    border: none;
}

.slick-next:before, .slick-prev:before {
    font-size: 5rem;
}

.slick-dots {
    bottom: 10px;
}

.slick-dots li button:before {
    font-size: 0rem;
    opacity: 1.0;
    color: #5C2976;
    width: 40px;
    height: 8px;
}

.slick-dots li.slick-active button:before {
    background: #5C2976;
    display: block;
}

.slick-dots li {
    border: #5C2976 solid 1px;
    border-radius: 0%;
    margin: 0 2px;
    width: 40px;
    height: 8px;
}



/* Startbox
---------------------------------------------------------*/

.startbox {
    text-decoration: none;
    color: #111;
}

.startbox article {
    margin-bottom: 1.5em;
}

.pop-img img {
    border-bottom: #80451D solid 4px;
}

.startbox-inner {
    padding: 0 1.5em;
    display: block;
    position: relative;
}

.block-content {
    color: #111;
}

.block-rub h2 {
    color: #251408;
    font-size: 2.0rem;
    font-weight: bold;
}

.boxLink {
    padding-bottom: 1.5em;
}

/* block2
---------------------------------------------------------*/
section.start-block-wrappe2 {
    padding: 1.5em 0;
    min-height: 5vh;
}

.block2-content {
    text-align: center;
    max-width: 850px;
    margin: 0 auto;
    width: 100%;
    color:white;
}

.block2-content h1 {
    color:white;
    font-size: 2.5rem;
}

/* block3
---------------------------------------------------------*/
.start-block-wrapper3 {
    min-height: 5vh;
    padding: 0;
}

.start-block-wrapper3 .container > h2 {
    text-align: center;
}

.zoombox {
    height: 18vw;
    overflow: hidden;
    position: relative;
    color: #fff;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom: 0;
    box-shadow: 0px 0px 0px 2px #fff;
}

.zoombox a {
    -webkit-transition: color 3s ease-in-out 0s;
    transition: color 3s ease-in-out 0s;
}

.zoombox a:hover {
    color: rgba(255, 255, 255, 1);
}

.zoombox_innerdiv {
    height: 100%;
    width: 100%;
    background-position: 50% 50%;
    background-size: cover;
    transform: scale(1);
    position: absolute;
    transition: transform 3s ease-in 0s;
}

.zoomboxar > a:hover .zoombox_innerdiv {
    transform: scale(1.2);
    transition: transform 3s ease-in 0s;
}

.zoombox_textdiv {
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0px 2em;
    text-align: center;
    position: absolute;
}

.zoombox h3 {
    padding-bottom: 35px;
    position: relative;
    text-align: center;
    font-size: 2.2em;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    font-weight: 700;
    color: white;
    text-transform: uppercase;
}

.zoombox h3:after {
    opacity: 0;
    content: "";
    position: absolute;
    background: #fff;
    height: 3px;
    width: 200px;
    margin: 45px 0 0 -100px;
    left: 50%;
    transition: 0.5s ease-in 0s;
}

.zoombox:hover h3:after {
    opacity: 1;
    transition: 0.5s ease-in 0s;
}

/* block4
---------------------------------------------------------*/
.start-block-wrapper4 {
    color: white;
}

.start-block-wrapper4 h3 {
    color: white;
    font-weight: bold;
}

.start-block-wrapper4 button {
    color: white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

/* block5
---------------------------------------------------------*/
section.start-block-wrapper5 {
    padding: 0;
}


/* Personal
---------------------------------------------------------*/
.stuff figure {
    position: relative;
    overflow: hidden;
    margin: 1%;
    background: #3085a3;
    text-align: center;
}

.ref-page .stuff figure {
    background: #000;
}

.stuff figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}

.stuff figure figcaption {
    padding: 1em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.stuff figure figcaption::before,
.stuff figure figcaption::after {
    pointer-events: none;
}

.stuff figure figcaption,
.stuff figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.stuff figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.stuff figure h2 {
    color:white;
    font-weight: 300;
    font-size:1.6rem;
    padding-top:0;
}

.stuff figure h2 span {
    font-weight: 800;
}

.stuff figure h2,
.stuff figure p {
    margin: 0;
}

.stuff figure p, .stuff figure .ref-content {
    background: rgba(255, 255, 255, 0.26);
    background:rgba(0,0,0,0.26);
    letter-spacing: 1px;
    font-size: 68.5%;
}

.stuff figure p span, .stuff figure  .ref-content span  {
    display:block;
    padding:0 0.5em;
    text-transform:none;
    font-weight:700;
}

.stuff figure p span a, ..tuff figure .ref-content span a  {
    color:white;
    text-decoration:none;
    font-weight:normal;
}

.stuff figure p span a:hover, .stuff figure .ref-content span a:hover {
    text-decoration:underline;
}


figure.effect-marley figcaption {
    text-align: right;
}

figure.effect-marley figcaption a {
    color:white;
    text-decoration:none;
    font-weight:normal;
}

figure.effect-marley figcaption a:hover {
    text-decoration:underline;
}

figure.effect-marley h2,
figure.effect-marley p {
    position: absolute;
    right: 30px;
    left: 30px;
    padding: 10px 0;
}

figure.effect-marley p, figure.effect-marley .ref-content {
    bottom: 30px;
    line-height: 1.5;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
}

figure.effect-marley h2 {
    top: 0px;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0,20px,0);
    transform: translate3d(0,20px,0);
}

figure.effect-marley:hover h2 {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-marley h2::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    background: #fff;
    content: '';
    -webkit-transform: translate3d(0,40px,0);
    transform: translate3d(0,40px,0);
}

figure.effect-marley h2::after,
figure.effect-marley p, figure.effect-marley .ref-content {
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-marley:hover h2::after,
figure.effect-marley:hover p, figure.effect-marley:hover .ref-content {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.stuff-page section {
    padding:0.5em;
}

/* sida
---------------------------------------------------------*/

.top-img {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height:25vh;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}


.inner-content  > ul {
    list-style-type: none;
    margin:0;
    padding:0;
}

.inner-content > ul li {
    padding-left: 1.2em;
    padding-bottom:0.4em;
    
}

.inner-content > ul li:before {
  content: "\f046"; /* FontAwesome char code inside the '' */
  font-family: FontAwesome; /* FontAwesome or whatever */
  display: inline-block;
  width: 1.2em; /* same as padding-left set on li */
  margin-left: -1.2em; /* same as padding-left set on li */
}

/* Referenser
---------------------------------------------------------*/
.ref-page figure.effect-marley h2 {
    top:0px;
    font-size:1.6rem;
}

.ref-content {
    position:absolute;
    background:#000 !important;
    bottom:0 !important;
    left:0;
    right:0;
    width:100%;
    text-align:left;
}

.ref-content span {
    display:block;
    padding:0.5em !important;
}

.ref-item {
      text-align: justify;
  column-gap: 3px;
  -webkit-column-gap: 6px;
  -webkit-columns: 3;
  -moz-columns: 3;
  columns: 3;
    position:relative;
}

.ref-item div {
    position:relative;
    border:red solid 1px;
    display:block;
}

body.Personal figcaption span:nth-child(2) {
    display:none !important;
}

/* Footer
---------------------------------------------------------*/

footer {
    color: white;
}

footer h4 {
    margin: 0;
    color: white;
    font-size: 1.5rem;
    border-bottom: #333 solid 1px;
}

footer .contact-prod ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer .contact-prod li {
    display: block;
    border-bottom: #333 solid 1px;
}

footer .contact-prod a {
    display: block;
    padding: 0.3em 0;
    text-decoration: none;
    color: #797979;
}

footer .contact-prod a:hover {
    color: white;
}

footer .contact-box {
    font-size: 0.9rem;
    color: #797979;
}

footer .contact-box span {
    display: block;
}

footer .contact-box a {
    color: #797979;
    text-decoration: none;
}

footer .contact-box a:hover {
    color: white;
}

footer .contact-box .contact-adress {
    padding-bottom: 0.5em;
    border-bottom: #333 solid 1px;
}

footer .contact-box .phone {
    border-bottom: #333 solid 1px;
    padding: 0.5em 0;
}

footer .contact-box .mail {
    padding: 0.5em 0;
}

.topsocial ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.topsocial li {
    display: inline-block;
    margin-right: 0.5em;
}

.topsocial li a {
    text-decoration: none;
    color: #fff;
    font-size: 1.3rem;
}

.topsocial li a:hover {
    color: #5D2976;
}

.bottom-logo img {
    max-width: 60%;
}



/* other
---------------------------------------------------------*/

.boltform label {
    display: none;
}

.boltform textarea {
    min-height: 120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color: #222;
}

.button, button, input[type=reset], input[type=submit] {
    margin: 0;
    background: #F28D1A;
    border: #F28D1A solid 1px;
    color: white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.button:hover, button:hover, input[type=reset]:hover, input[type=submit]:hover {
    background: #000;
    border: #000 solid 1px;
}

button[type=submit], input[type=submit] {
    background: #F28D1A;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: #000;
    border: #000 solid 1px;
}

a {
    color: #d50000;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: #282d32;
}

body {
    font-family: NewsCycle;
    font-weight: 400;
    color: #282d32;
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family:PlayfairDisplay;
    font-family:"Myriad Pro", arial, helvetica, sans-serif;
    margin-top:0;
    color:#000;
}

h1 {
    font-weight:700;
}

.article-img {
    overflow:hidden;
}

.articleSliderSmall {
    width:auto !important;
}

.articleSliderSmall img {
    margin-right:1px;
    border:#C3C3C3 solid 1px;
    cursor:pointer;
}

.slider-nav {
    margin-top:0.3em;
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:70vw;
    }
    .homepage header {
        background:transparent;
    }
    .top-img {
        height:38vw;
    }
    figure.effect-marley h2, .ref-page figure.effect-marley h2 {
        font-size:1.5rem;
        top:0;
    }
    .bildspel_titel {
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        font-size:0.9rem;
        padding:0 1.5em;
    }
    .bildspel_titel h1, .bildspel_titel h2 {
        font-size:2.5rem;
    }
    .zoombox h3 {
        font-size:2rem;
    }
    #menu-icon {
        top:10px !important;
    }
        
}

@media (max-width: 750px) and (min-width: 551px ) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:40vw;
    }
}

@media (max-width: 800px) {
    .btnDown {
        bottom:5vh;
    }
    .bildspel_titel .container {
        font-size:1.1em;
    }
    .logo img {
        max-width:70%;
    }
    .zoombox h1, .zoombox h2 {
        font-size:1.7rem;
    }    
}

@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:black !important;
    }
    
    #menu-icon {
        z-index:100;
        position:absolute;
        top:20px;
        left:10px;
    }

    #menu-icon span {
        border:2px solid white;
    }
    .menu li, .menu li ul, .menu li:hover ul {
        margin:0;
    }
    .menu .active a, .menu li a:hover {
        background:black;
        color:#eee;
    }

}


@media (min-width: var(--breakpoint-menu)) {
    .slider-caption-outer {
    bottom: 0;
    left: 0;
    overflow: visible;
    position: absolute;
    text-align: center;
    right: 0;
    top: 0;
    }
    .slider-caption {
    position: absolute;
    bottom: 30%;
    width: 100%;
    right: 0;
    text-align: right;
    display: block;
    padding: 0;
    z-index: 3;
    }
}

@media (min-width: var(--breakpoint-xs)) {
    .topmenu {
        margin-top:1.5em;
    }
    .start-block-wrapper4 h3 {
        font-size:2.0rem;
    }
}


@media (max-width: var(--breakpoint-m)) {}
@media (max-width: var(--breakpoint-s)) {}
@media (max-width: var(--breakpoint-xs)) {}
